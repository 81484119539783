<template>
    <div class="ContactAgreement">
    <div style="display: flex;padding: 20px;">
        <div class="condition" style="white-space: nowrap">
            <span>合同名称：</span>
            <a-input
                    v-model="FileName"
                    style="width: 180px; margin-right: 15px"
                    placeholder="搜索合同名"
            ></a-input>
        </div>
        <div class="condition">
            <span>合同状态：</span>
            <a-select
                    style="width: 89px; margin-right: 15px"
                    v-model="uploadStatus_value"
            >
                <a-select-option
                        :value="item.key"
                        v-for="(item, index) in uploadStatus"
                >{{ item.name }}
                </a-select-option>
            </a-select>
        </div>
        <div class="condition" style="white-space: nowrap;display: flex">
            <a-button style="margin-right: 20px" @click="_reset()"
            >重置
            </a-button>
            <a-button type="primary" @click="search" style="margin-right: 20px">查询</a-button>
            <a-button type="primary" @click="addContact" style="background-color: #56AF3D;border-color: #56AF3D"
            >新增合同模板
            </a-button>
        </div>
    </div>
    <div style="display: flex;flex-direction: row;flex-flow: wrap">
        <div  v-for="item in list" style=" padding:20px;">
            <a-card hoverable style="width: 300px;height: 324.84px;display: flex;flex-direction: column; justify-content: space-between;">
                <img slot="cover" alt="example" :src="path" style="width: 298px;height: 181px;"/>
                <template slot="actions" class="ant-card-actions">
                    <a-icon key="setting" type="setting" />
                    <a-icon key="edit" type="edit" @click="toEdit"/>
                    <a-icon key="ellipsis" type="ellipsis" />
                </template>
                <a-card-meta :title="item.ManageAreaName" :description="item.Remark">
                    <a-avatar
                            slot="avatar"
                            src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
                    />
                </a-card-meta>
            </a-card>
        </div>
    </div>
    </div>
</template>

<script>
    export default {
        name: "List",
        data () {
            return {
                list:[],
                path:require('../../../public/logo.png'),
            }
        },
        mounted () {
            this.getList();
        },
        methods:{
            toEdit(){
                this.$router.push({ path: '/editContact', query: { Id: 8 }})
            },
            addContact(){
                this.$router.push({ path: '/addContact', query: { Id: 8 }})
            },
            search(){

                // this.list = [];
            },
            getList(){
                this.$axios.get(
                    20016,
                    { Id: ''},
                    res => {
                        if (res.data.code == 1) {
                            this.list = res.data.data
                        } else {
                            this.$message.error(res.data.msg)
                        }
                    }
                )
            }
        }
    }
</script>

<style lang="less" scoped>
    .ContactAgreement {
        background-color: white;
    }
</style>